import React, { useState } from 'react';
import { Modal, TextInput, FileUploader, ToastNotification, Dropdown } from '@carbon/react';
import Papa from 'papaparse'; // Import Papa Parse
import './AddClimateData.scss';
import { set, child } from "firebase/database";
import { dbRef, auth } from "./../../firebase/firebase"; // Replace with the actual path to your Firebase config

const AddClimateData = ({ onClose }) => {
  const [weekNumber, setWeekNumber] = useState('');
  const [serre, setSerre] = useState('');
  const [file, setFile] = useState(null);
  const [errors, setErrors] = useState({});
  const [showNotification, setShowNotification] = useState(false);

  const handleSave = () => {
    const newErrors = {};

    // Check week number is filled
    if (!weekNumber.trim()) {
      newErrors.weekNumber = "Week number is required!";
    }

    // Check serre is selected
    if (!serre.trim()) {
      newErrors.serre = "Location (Serre) is required!";
    }

    // Check file is uploaded
    if (!file) {
      newErrors.file = "CSV file is required!";
    }

    if (Object.keys(newErrors).length > 0) {
      // If there are errors, don't save and set the errors to state
      setErrors(newErrors);
      return;
    }

    // If no errors: read CSV data
    Papa.parse(file, {
      header: true,
      complete: (results) => {
        const csvData = results.data; // Parsed CSV data

        const currentDate = new Date().toISOString().split('T')[0];
        const username = auth.currentUser.displayName || auth.currentUser.email.replace(/[.#$@[\]]/g, '_'); // Fallback to email if displayName is not available

        const climateDataRef = child(dbRef, `ClimateData/${username}/${weekNumber}/${serre}/${currentDate}`);
        
        // Save each row of the CSV data to Firebase using the 'label' as the key
        csvData.forEach((row) => {
          if (row.label) {
            set(child(climateDataRef, row.label), row)
              .then(() => {
                console.log("Data saved successfully.");
              })
              .catch((error) => {
                console.error("Error saving data: ", error);
              });
          }
        });

        // Show notification and clear the fields after the notification is closed
        setShowNotification(true);
        setTimeout(() => {
          setShowNotification(false);
          setWeekNumber('');
          setSerre('');
          setFile(null);
          onClose();
        }, 3000); // Show notification for 3 seconds
      },
      error: (err) => {
        console.error("Error while parsing CSV:", err);
        setErrors({ file: "Error while parsing CSV file!" });
      },
    });
  };

  return (
    <div>
      {showNotification && (
        <ToastNotification
          kind="success"
          title="Upload Successful"
          subtitle="The climate data has been uploaded successfully."
          onCloseButtonClick={() => setShowNotification(false)}
          timeout={3000} // Notification will auto-hide after 3 seconds
        />
      )}
      <Modal
        open
        modalHeading="Add Climate Data"
        modalLabel=""
        primaryButtonText="Save"
        secondaryButtonText="Cancel"
        onRequestClose={onClose}
        onRequestSubmit={handleSave}
        danger={false}
        className="climate-data-modal"
      >
        <div className="climate-data-modal-content">
          <div className="form-field">
            <label className="form-label">Week Number:</label>
            <TextInput
              id="weekNumber"
              value={weekNumber}
              onChange={(e) => {
                setWeekNumber(e.target.value);
                if (errors.weekNumber) {
                  // reset the error when the user starts typing
                  setErrors((errs) => ({ ...errs, weekNumber: null }));
                }
              }}
              invalid={!!errors.weekNumber}
              invalidText={errors.weekNumber || ""}
              className="form-input"
            />
          </div>

          <div className="form-field">
            <label className="form-label">Location (Serre):</label>
            <Dropdown
              id="serre"
              label="Select Serre"
              items={['Serre 1', 'Serre 2', 'Serre 3', 'Serre 4']}
              selectedItem={serre}
              onChange={({ selectedItem }) => {
                setSerre(selectedItem);
                if (errors.serre) {
                  // reset the error when the user starts selecting
                  setErrors((errs) => ({ ...errs, serre: null }));
                }
              }}
              className="form-input"
            />
            {errors.serre && <div className="invalid-text">{errors.serre}</div>}
          </div>

          <div className="form-field">
            <label className="form-label">Upload CSV:</label>
            <FileUploader
              accept={['.csv']}
              buttonLabel="Add file"
              filenameStatus="edit"
              disableLabelChanges={true}
              multiple={false}
              onChange={(e) => {
                setFile(e.target.files[0]);
                if (errors.file) {
                  // reset the error when the user starts typing
                  setErrors((errs) => ({ ...errs, file: null }));
                }
              }}
              invalid={!!errors.file}
              invalidText={errors.file || ""}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AddClimateData;
