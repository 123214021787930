import React, { useState } from 'react';
import { Modal, TextInput, Dropdown } from '@carbon/react';
import './AddHarvestModal.scss';
import { set, child } from "firebase/database";
import { dbRef, auth } from "./../../firebase/firebase"; // Replace with the actual path to your Firebase config

const HarvestModal = ({ onClose }) => {
  const [weekNumber, setWeekNumber] = useState('');
  const [weight, setWeight] = useState('');
  const [tomatoType, setTomatoType] = useState('');
  const [location, setLocation] = useState('');
  const [errors, setErrors] = useState({});

  const handleSave = () => {
    const newErrors = {};

    // Check if week number is filled
    if (!weekNumber.trim()) {
      newErrors.weekNumber = "Week number is required!";
    }

    // Check if weight is filled
    if (!weight.trim()) {
      newErrors.weight = "Weight is required!";
    }

    // Check if tomato type is selected
    if (!tomatoType.trim()) {
      newErrors.tomatoType = "Tomato type is required!";
    }

    // Check if location is selected
    if (!location.trim()) {
      newErrors.location = "Location is required!";
    }

    if (Object.keys(newErrors).length > 0) {
      // If there are errors, don't save and set the errors to state
      setErrors(newErrors);
      return;
    }

    // If no errors:
    const data = {
      weekNumber,
      weight,
      tomatoType,
      location,
    };

    // Get the current date in YYYY-MM-DD format
    const currentDate = new Date().toISOString().split('T')[0];
    const username = auth.currentUser.displayName || auth.currentUser.email.replace(/[.#$@[\]]/g, '_'); // Fallback to email if displayName is not available

    // Create a reference to the new collection and date-based key
    const harvestDataRef = child(dbRef, `WeeklyHarvestData/${username}/${currentDate}`);

    // Save to Firebase
    set(harvestDataRef, data)
      .then(() => {
        console.log("Data saved successfully.");
        // Clear the fields
        setWeekNumber('');
        setWeight('');
        setTomatoType('');
        setLocation('');
        onClose();
      })
      .catch((error) => {
        console.error("Error saving data: ", error);
      });
  };

  return (
    <Modal
      open
      modalHeading="Add Weekly Harvest"
      modalLabel=""
      primaryButtonText="Save"
      secondaryButtonText="Cancel"
      onRequestClose={onClose}
      onRequestSubmit={handleSave}
      danger={false}
      className="harvest-modal"
    >
      <div className="harvest-modal-content">
        <div className="form-field">
          <label className="form-label">Week Number:</label>
          <TextInput
            id="weekNumber"
            value={weekNumber}
            onChange={(e) => {
              setWeekNumber(e.target.value);
              if (errors.weekNumber) {
                // Reset the error when the user starts typing
                setErrors((errs) => ({ ...errs, weekNumber: null }));
              }
            }}
            invalid={!!errors.weekNumber}
            invalidText={errors.weekNumber || ""}
            className="form-input"
          />
        </div>

        <div className="form-field">
          <label className="form-label">Weight (kg/m²):</label>
          <TextInput
            id="weight"
            value={weight}
            onChange={(e) => {
              setWeight(e.target.value);
              if (errors.weight) {
                // Reset the error when the user starts typing
                setErrors((errs) => ({ ...errs, weight: null }));
              }
            }}
            invalid={!!errors.weight}
            invalidText={errors.weight || ""}
            className="form-input"
          />
        </div>

        <div className="form-field">
          <label className="form-label">Tomato Type:</label>
          <Dropdown
            id="tomatoType"
            label="Select tomato type"
            items={['Sunstream', 'Adorion']}
            selectedItem={tomatoType}
            onChange={({ selectedItem }) => {
              setTomatoType(selectedItem);
              if (errors.tomatoType) {
                // Reset the error when the user starts selecting
                setErrors((errs) => ({ ...errs, tomatoType: null }));
              }
            }}
            className="form-input"
          />
          {errors.tomatoType && <div className="invalid-text">{errors.tomatoType}</div>}
        </div>

        <div className="form-field">
          <label className="form-label">Location:</label>
          <Dropdown
            id="location"
            label="Select location"
            items={['Serre 1', 'Serre 2', 'Serre 3', 'Serre 4']}
            selectedItem={location}
            onChange={({ selectedItem }) => {
              setLocation(selectedItem);
              if (errors.location) {
                // Reset the error when the user starts selecting
                setErrors((errs) => ({ ...errs, location: null }));
              }
            }}
            className="form-input"
          />
          {errors.location && <div className="invalid-text">{errors.location}</div>}
        </div>
      </div>
    </Modal>
  );
};

export default HarvestModal;
